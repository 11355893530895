import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --primary-dark-blue: #0F265A;
    --primary-text-color: #4B4B4D;
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  input, label {
    font-family: Montserrat, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;    
    font-size: 1.6rem;
    border: none;
    outline-color: var(---primary-dark-blue);
    &:focus, &:active, &:hover {
      outline-width: 2px;
    }

    &:invalid {
      outline-color: #D93030;
    }
  }


  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-display: swap;
    font-size: 1.6rem;
    font-family: Montserrat, Arial, 'Arial Narrow', 'Franklin Gothic Medium', sans-serif;
    font-weight: 400;
    color: #0F265A;
    overflow-x: hidden;
    min-height: 100vh;
  }

  p {
    color: var(--primary-text-color);
    line-height: 140.6%;
    font-weight: 400;
    &.light {
      font-weight: 300;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 140.6%;
  }

  h2 {
    font-weight: 600;
    font-size: 1.8rem;
  }

  h4 {
    font-weight: 600;
    font-size: 1.8rem;
  }

  strong{
    font-weight: 600;
  }

  ul, ol {
    list-style: none;
  }

  section .container {
    padding: 0 1.6rem;
  }

  .App {
    overflow-x: hidden;
  }

  .ppp * { margin: revert; padding: revert; }
  .ppp ul, .ppp ol { list-style: revert; }
  .ppp li { color: var(--primary-text-color); margin-block-start: 0.5em; }
  .ppp a:active, .ppp a:link, .ppp a:visited, .ppp a:hover {
    color: var(--primary-dark-blue);
    text-decoration: revert;
  }
  
  .watermark {
    display: inline-block;

    position: relative;
    & svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-height: 100%;
      width: 100%;
      max-width: 100%;
      z-index: -1;
    }
  }

  .container {
    max-width: 107.2rem;
    margin: 0 auto;
  }

  a:active, a:link, a:visited, a:hover {
    color: currentColor;
    text-decoration: none;
  }

  button.designed {
    & span {
      display: inline-block;
      min-width: 15.1rem;
      text-align: center;
    }
    position: relative;
    transition: all .3s;
    &::before {
      content: "";
      transition: all .3s;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0rem;
      left: 0rem;
      border-radius: 0.5rem;
      z-index: -1;
      background: #27cffb;
    }

    &:hover {
      &::before {
        top: 0.6rem;
        left: 0.6rem;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    button.designed {
      padding-top: 1.5rem;
      padding-bottom: 1.591rem;
      margin-bottom: 2.4rem;
      & span {
        font-size: 1.6rem;
        min-width: 12.1rem;
      }
    }
  }

  @media screen and (min-width: 901px) and (max-width: 1080px) {
    .container {
      max-width: 92.8rem;
      padding: 0 1.6rem;
      box-sizing: content-box;  
    }
  }

  @media screen and (max-width: 900px) {
    h1 {
      font-size: 2.4rem;
    }
    
    .container {
      max-width: 70.4rem;
    }

    h2 {
      font-size: 1.6rem;
    }
  }

  @media screen and (min-width: 711px) and (max-width: 900px) {
    .container {
      box-sizing: content-box;
    }
  }

  @media screen and (max-width: 710px) {
    .container {
      max-width: 100%;
    }
  }
`;
