import styled from "styled-components";

export default styled.button<{size: "normal" | "small"}>`
  background: var(--primary-dark-blue);
  color: white;
  text-align: center;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-radius: 0.5rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-top: ${props => (props.size === "normal" ? "1.9rem" : "1.1rem")};
  padding-bottom: ${props => (props.size === "normal" ? "1.9rem" : "1.1rem")};
  font-size: ${props => (props.size === "normal" ? "1.8rem" : "1.6rem")};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background: #2951ab;
  }
`;
