import React, {useEffect} from "react";
import loadable, { LoadableComponent } from "@loadable/component";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";

import hotspotTerms from "./terms/hotspotTerms";
import affiliateReferralProgramAgreement from "./terms/affiliateReferralProgramAgreement";
import partnerTerms from "./terms/partnerTerms";
import termsAndConditions from "./terms/termsAndConditions";
import privacyPolicy from "./terms/privacyPolicy";

import Header from "./components/Header";
import { TermsProps } from './shared/types/Terms';

import cityRoutes from './data/cityRoutes';

import { initializeApp } from 'firebase';
import 'firebase/auth';
import 'firebase/database';

try{
  initializeApp({
    apiKey: "AIzaSyCOMEQNFWmZFpPuuiOVqaQq8DU-X0XAS9Y",
    authDomain: "coastfi-referral-program.firebaseapp.com",
    databaseURL: "https://coastfi-referral-program.firebaseio.com",
    projectId: "coastfi-referral-program",
    storageBucket: "coastfi-referral-program.appspot.com",
    messagingSenderId: "1081125489703",
    appId: "1:1081125489703:web:31a8ed52c8a3465b8d5202"
  });
}catch({message}){
  console.log('Already initialized.');
}

const HomePage = loadable(() => import("./pages/HomePage"));
const AppPage = loadable(() => import("./pages/AppPage"));
const ReferralPage = loadable(() => import("./pages/ReferralPage"));
const PrivacyPage = loadable(() => import("./pages/PrivacyPage"));
const B2BPage = loadable(() => import("./pages/B2BPage"));
const CityPage = loadable(() => import("./pages/CityPage"));
const AmbassadorPage = loadable(() => import("./pages/AmbassadorPage"));
const Footer = loadable(() => import("./components/Footer"));
const TermsContentSection = loadable(() => import('./components/terms-page/TermsContentSection'))
const InstallPage = loadable(() => import('./pages/InstallPage'))
const InstallPageModel1 = loadable(() => import('./pages/InstallPageModel1'))
const InstallPageModel2 = loadable(() => import('./pages/InstallPageModel2'))
const InstallPageModel3 = loadable(() => import('./pages/InstallPageModel3'))

interface IPage extends RouteProps{
  title: string,
  component: LoadableComponent<any>
}

function Page({
  title,
  component: Component,
  ...props
}: IPage){
  useEffect(() => {
    if(title) {
      window.scrollTo(0,0);
      document.title = title;
    }
    const timer = setTimeout(() => {
      if(window.document.location.hash) {
        const frag = window.document.location.hash.slice(1);
        const fragged = document.getElementById(frag);
        if(fragged) {
          fragged.scrollIntoView();
        }
      }
    }, 256);
    return () => clearTimeout(timer);
  }, [title]);

  return (
    <Component {...props} />
  );
}

interface ITermsPage extends RouteProps{
  title: string,
  terms: TermsProps[]
}

function TermsPage({
  title,
  terms,
  ...props
}: ITermsPage){
  const termsComponent = () => <TermsContentSection title={title} terms={terms} />

  return (
    <Page
      {...props}
      title={`${title} - CoastFi`}
      component={termsComponent as any}
    />
  )
}

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <Switch>
          <Route exact path="/" render={(props) => <Page {...props} title={'CoastFi - Earn $100 renting a tiny fraction of your internet'} component={HomePage} />} />
          <Route exact path="/app" render={(props => <Page {...props} title={'CoastFi - App'} component={AppPage} />)} />
          <Route exact path="/r/:ref_id" render={(props => <Page {...props} title={'CoastFi - Ref'} component={ReferralPage} />)} />
          <Route exact path="/it-installers" render={(props => <Page {...props} title={'CoastFi - Earn passive income installing internet hotspots'} component={B2BPage} />)} />
          <Route exact path="/ambassador" render={(props => <Page {...props} title={'CoastFi - Earn $100s supporting smart tech'} component={AmbassadorPage} />)} />
          <Route exact path="/install" render={(props => <Page {...props} title={'CoastFi Hotspot Installation Instructions'} component={InstallPage} />)} />
          <Route exact path="/install/model-1" render={(props => <Page {...props} title={'CoastFi Hotspot Installation Instructions'} component={InstallPageModel1} />)} />
          <Route exact path="/install/model-2" render={(props => <Page {...props} title={'CoastFi Hotspot Installation Instructions'} component={InstallPageModel2} />)} />
          <Route exact path="/install/model-3" render={(props => <Page {...props} title={'CoastFi Hotspot Installation Instructions'} component={InstallPageModel3} />)} />
          {
            cityRoutes.map(city => (
              <Route
                key={city.path}
                exact
                path={city.path}
                render={(props) => <Page title={`CoastFi - Earn $100s supporting smart tech in ${city.name}`} {...props} component={CityPage} />} />
            ))
          }
          <Route
            exact
            path="/hotspot-terms-conditions"
            render={(props) => <TermsPage title={`Hotspot Terms & Conditions`} {...props} terms={hotspotTerms} />} />
          <Route
            exact
            path="/affiliate-referral-program-agreement"
            render={(props) => <TermsPage title={`Affiliate Referral Program Agreement`} {...props} terms={affiliateReferralProgramAgreement} />} />
          <Route
            exact
            path="/partner-terms"
            render={(props) => <TermsPage title={`Partner Terms & Conditions`} {...props} terms={partnerTerms} />} />
          <Route
            exact
            path="/terms-conditions"
            render={(props) => <TermsPage title={`Terms & Conditions`} {...props} terms={termsAndConditions} />} />
          <Route
            exact
            path="/privacy-policy"
             render={(props => <Page {...props} title={'Privacy Policy'} component={PrivacyPage} />)} />
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
  </div>
  );
};

export default App;
