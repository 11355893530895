const wrapOL = (arr: string[]) => `<ol>${arr.map(v => `<li><p>${v}</p></li>`).join('')}</ol>`

export default [
  {
    hidden: true,
    term: "General",
    condition: `
      <p>
      <strong>Last Modified</strong>: April 19, 2021<br /><br />
      <strong>IMPORTANT NOTICE</strong>: PLEASE NOTE THAT THESE TERMS AND CONDITIONS REQUIRE THAT DISPUTES BE RESOLVED IN THE STATE OF NEW YORK, AND THAT YOU WAIVE ANY RIGHT TO BRING CLASS ACTIONS AGAINST US. PLEASE REVIEW SECTION 14 BELOW ENTITLED "GOVERNING LAW, JURISDICTION AND NO CLASS ACTIONS" FOR COMPLETE DETAILS.<br /><br />
      Growlogics 2 LLC d/b/a CoastFi ("CoastFi", “we”, “us” or “our”) licenses the use of CoastFi Hotspots to support a network of Internet of Things devices. These Terms and Conditions apply to the Hotspot and to any other products, services, software or hardware associated with the Hotspot (collectively, the “Product”). You must accept these Terms and Conditions in order to use the Product. If you do not understand or have questions about these Terms and Conditions, immediately stop all use of the Product and contact help@coastfi.com.
      </p>
    `,
  },
  {
    term: "Acceptance of the Terms and Conditions.",
    condition: wrapOL([
      'By signing up for, installing, accessing and/or using the Product in any manner, you indicate that you have read and understand this Terms and Conditions Agreement (the "Terms"),which incorporates by reference our Privacy Policy located at <a href="/privacy-policy">https://www.coastfi.com/privacy-policy</a>, that you meet the Eligibility requirements provided in the Eligibility section (see below), and that you agree to be bound by these Terms in their entirety.  Your continued use of the Product constitutes your agreement to the most current version of the Terms. If at any time you do not agree to the Terms, you must cease your use of the Product.',
      'To provide the Product, we need information about you. Please refer to our <a href="/privacy-policy">Privacy Policy</a> to help you understand what information we collect, how we use that information and what choices you have regarding your information when you use our Product.'
    ]),
  },
  {
    term: "Eligibility and User Accounts.",
    condition: wrapOL([
      `<strong>Eligibility. </strong>You represent and warrant that you are an individual person at least 18 years of age. If you are under age 18, you may not, under any circumstances or for any reason, use the Product. By using the Product, you agree that you have read, understood and agree to be bound by these Terms and by any changes or modifications that we may make hereto as well as all other documents incorporated by reference.<br/><br/>
      You are solely responsible for ensuring that these Terms are in compliance with all laws, rules and regulations applicable to you and the right to use the Product is revoked where these Terms or use of the Product is prohibited or to the extent offering or provision of the Product conflicts with any applicable law, rule or regulation.<br/><br/>
      We may, in our sole discretion, refuse to offer the Product to any person or entity and change this eligibility criteria at any time.
      `,
      `<strong>Application. </strong>We may require you to submit an application to see if you qualify for use of the Product. You must provide accurate and complete information in your application, including the address in which the Product shall be installed ("Installation Site"). If you provide any information that is inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such information is inaccurate, not current or incomplete, or otherwise violates our Terms, we may deny your application for the Product and/or take such other action as we deem appropriate, at our sole discretion.`,
      `Without limiting anything in these Terms, we reserve the right, in our sole discretion, to restrict, suspend, or terminate your use of the Product, for any or no reason, with or without prior notice, and without liability. We expressly reserve the right to restrict, suspend and/or terminate your use of the Product if we determine, in our sole discretion, that you have violated these Terms.`
    ])
  },
  {
    term: "Telephone Communications With You Regarding the Products.",
    condition: wrapOL([
      'You agree that CoastFi and its agents may call or text you at any phone number (landline or wireless) that you provide to us, using an automated dialing system and/or a prerecorded message, for informational, non-promotional and/or marketing purposes, such as notifying you when the Product is not fully operational. You agree to notify us: (1) if any such phone number changes; (2) is no longer active; or (3) is ported from a landline to a wireless phone number.'
    ])
  },
  {
    term: "Limited License to Use.",
    condition: wrapOL([
      'Subject to your compliance with these Terms, CoastFi grants you a limited, personal, non-exclusive, revocable, non-transferable, and non-assignable license to install and operate the Product as set forth in these Terms at the Installation Site. You do not acquire any ownership or other rights in the Product or in anything generated by or resulting from the operation of the Product, except for the limited license granted by these Terms.',
      'Except as expressly permitted in these Terms or under applicable law, you may not nor may you permit, facilitate or authorize any third party to: (a) copy, modify, translate, or otherwise create derivative works based on the Product; (b) distribute, transfer, sublicense, lease, lend, or rent the Product to any third party; or (c) reverse engineer, decompile, disassemble, or otherwise attempt to extract and/or derive the source code of the Product. CoastFi reserves all rights in and to the Product not expressly granted to you under these Terms.'
    ])
  },
  {
    term: "Intellectual Property Rights.",
    condition: wrapOL([
      '<strong>Product Ownership. </strong>You acknowledge and agree that the Product is protected by copyright, trademark, and other laws of the United States and foreign countries. The rights granted to you under these Terms do not convey any ownership rights in the Product or in the intellectual property rights of CoastFi associated therewith. Subject only to your limited right to install and operate the Product as expressly stated herein, all rights, title and interest in and to the Product, including all intellectual property rights, shall remain the sole and exclusive property of CoastFi.',
      '<strong>Feedback. </strong>We may offer you the opportunity to provide feedback/suggestions. In the event that you provide us with any feedback, suggestions or ideas regarding the Product, whether solicited or unsolicited, including without limitation: any flaws, errors, bugs, anomalies, problems with the Product; suggestions and/or ideas on how to improve or change the Product; or suggestions and/or ideas otherwise related to the Product (collectively, “Feedback”), you hereby assign to us all rights in the Feedback, including but not limited to, the right to use such Feedback and related information in any manner we deem appropriate, without compensation or notice to, or approval from, you and you expressly waive all moral rights you may have therein.'
    ])
  },
  {
    term: "Conduct, General Prohibitions and Enforcement Rights.",
    condition: wrapOL([
      'Immediately after your receipt of the Product, you agree to install and operate the Product pursuant to any and all instructions provided to you by CoastFi, as may be modified from time to time, and to keep the Product installed and fully operational, including but not limited to maintaining the internet connectivity and/or other operability of the installed Product (the "Operability Requirements") at all times during the Term. You agree not to alter and/or modify the Product, and/or share, transfer, sell, lease and/or grant any interest in it to any third party.',
      'As a condition of use, you agree not to use the Product for any purpose that is prohibited by these Terms, any contract that you have or do enter(ed) into, and/or by applicable local, state, national and international laws and regulations. You agree that you are responsible for your own activities and conduct and for any consequences thereof. Violation of our Terms or applicable laws and regulations may result in CoastFi terminating your license to use the Product.',
      '<strong>Restrictions on Use. </strong> Except as otherwise set forth in these Terms, you may not nor may you permit, facilitate or authorize any third party to: (a) use the Product other than as permitted by these Terms; (b) remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Product; (c) access, tamper with or use the Product in any manner that could damage, disable, or otherwise interfere with or disrupt the Product, any networks or security systems; (d) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms from any part of the Product, except to the limited extent applicable laws specifically prohibit such restriction; (e) copy, modify, translate or otherwise create derivative works of the Product; (f) distribute, transfer, sublicense, lease, lend, or rent the Product to any third party; (g) extract portions of the software or hardware from the Product for any purpose; (h) collect, solicit, or attempt to derive any personally identifiable information (such as passwords, names, locations and/or contact information) of other CoastFi customers, and/or (i) access the Product to (i) build a competitive product or service; or (2) copy any, or build a product using, similar ideas, features, or functions of the Product.',
      'We reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce these Terms, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to support requests, (v) protect the rights, property or safety of us, you and the public, and/or (vi) ensure the proper functionality of the Product.',
      'ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE PRODUCT, INCLUDING WITHOUT LIMITATION UNDERMINING OR MANIPULATING THE LEGITIMATE OPERATION OF ANY HARDWARE OR SOFTWARE, IS A BREACH OF COASTFI’S TERMS AND MAY BE A BREACH OR VIOLATION OF CRIMINAL AND CIVIL LAWS.'
    ])
  },
  {
    term: "Payment.",
    condition: wrapOL([
      '<strong>Initial Payment. </strong>CoastFi shall remit to you a fixed payment (as determined by CoastFi in its discretion) in exchange for your successful installation of the Product and meeting the Operability Requirements for the 12 month period following the successful installation of the Product (the "Initial Period"). Payment for this Initial Period shall be remitted to you within 14 days after your successful installation of the Product. You agree that CoastFi shall be entitled to a refund of the pro rata portion of the payment made to you for the period of time that the Operability Requirements were not met.',
      '<strong>Subsequent Payments. </strong>Every 12 months following the Initial Period, CoastFi shall remit to you a fixed payment (as determined by CoastFi in its discretion), provided that (i) the Operability Requirements were met for that 12 month period and (ii) you contact CoastFi via email (help@coastfi.com) within 30 days after the completion of each such 12 month period.',
      'Payments may be delayed due to holidays, office closings, processing and other delays. Payments may be pro-rated for partial months. Payment will be remitted via Paypal, Venmo, Zelle, or check, to the Account specified by you.',
      'CoastFi reserves the right to revoke or change the amount of the payment without notice for any reason, in its sole and absolute discretion, including, but not limited to, issues relating to Product uptime, CoastFi network outages, and Product performance.'
    ])
  },
  {
    term: "Confidentiality.",
    condition: wrapOL([
      `You agree to maintain the confidentiality of any Confidential Information of CoastFi that you was obtained or that you have gained access to. With regard to the CoastFi’s Confidential Information you shall (a) use and copy the Confidential Information only for purposes of performing your obligations under these Terms, (b) hold the Confidential Information in strict confidence, and (c) protect and safeguard the confidentiality of the Confidential Information with at least the same degree of care as you protect and safeguard your own confidential information (but not using less than a reasonable degree of care). You may disclose that portion of the Confidential Information which, in the judgment of its counsel, the recipient is required to disclose by law, by regulation, or pursuant to the order or requirement of a court, administrative agency or other governmental body with proper jurisdiction; provided that you notify CoastFi in a reasonable time prior to making such disclosure and cooperate with CoastFi's efforts to seek a protective order or otherwise prevent or restrict such disclosure. "Confidential Information" means all information disclosed by CoastFi to you that is designated, at the time of disclosure, as confidential or that is of a nature that would reasonably be considered confidential, and does not include information that you can show (i) is known publicly through no breach of these Terms, (ii) was in its possession free of any obligation of confidence at the time of disclosure, (iii) was received from a third party free to disclose such information without restriction, or (iv) was independently developed without using or referring to the information of the disclosing party or breaching these Terms.`
    ])
  },
  {
    term: 'Term and Termination.',
    condition: wrapOL([
      'These Terms shall commence on the date that the Product is delivered to you and shall continue until terminated (the “Term”).',
      'CoastFi may terminate, at our sole discretion, these Terms at any time upon notice to you including if (a) you fail to comply with these Terms; (b) we suspect fraud or misuse by you of the Product; (c) we suspect any other unlawful activity associated with your operation of the Product, or (d) for any reason, in our sole discretion. You may discontinue use of the Product at any time provided that (i) you notify CoastFi of such discontinuation via email (help@coastfi.com) and (ii) return the Product to CoastFi pursuant to CoastFi’s shipping instructions.',
      `If you discontinue your use of the Product, or CoastFi otherwise terminates your license to use the Product (for whatever reason), you agree that you no longer have the right to keep or use the Product and you must promptly return the Product pursuant to CoastFi's shipping instructions. The Product must be returned to CoastFi in the same condition as when received, ordinary wear and tear excepted. Absent other instructions, if you fail to return the Product, you agree to pay any expenses CoastFi incurs in trying to retrieve the Product. Failure of CoastFi to pursue the return of the Product does not mean that CoastFi has abandoned the Product. CoastFi may, at its sole discretion, impose a charge for the unreturned Product equal to the fair market value of the Product. Any charge for the unreturned Product shall be due with 15 days from the invoice date.`,
      'If the Product is damaged by you, destroyed, lost or stolen while in your possession, you are responsible for the cost of repair or replacement of the Product.',
      'Upon termination of the operation of the Product or these Terms, for any reason, the following provisions of these Terms will survive: "Intellectual Property Rights", "Conduct, General Prohibitions and Enforcement Rights", “Confidentiality”, "Waiver of Unknown Claims under California Civil Code § 1542","Disclaimer of Warranties", "Limitations of Liability", "Indemnification", "Governing Law, Jurisdiction and No Class Actions", "Miscellaneous" and any other provision that provides for itself for survival or which by its nature should survive termination of these Terms.'
    ])
  },
  {
    term: 'Waiver of Unknown Claims under California Civil Code § 1542.',
    condition: wrapOL([
      'It is possible that other claims not known to you will develop or be discovered arising from your use of the Product or from the activities discussed throughout these Terms.  You acknowledge that your releases, indemnifications and waivers provided under these Terms are expressly intended to cover and include all such claims, including all rights of action therefor. You acknowledge that the claims released in those sections, may include unknown claims, and nevertheless waive California Civil Code § 1542 as to any such unknown claims. California Civil Code § 1542 reads as follows:<br /><br /><p style="padding-left: 2rem">A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.</p><br /><p>You acknowledge and understand the significance and consequences of this specific waiver of California Civil Code § 1542.</p>'
    ])
  },
  {
    term: 'Disclaimer of Warranties.',
    condition: wrapOL([
      'OUR PRODUCT DOES NOT GENERALLY INTERFERE WITH THE OPERATION OF YOUR LOCAL NETWORK OR YOUR INTERNET CONNECTIVITY, HOWEVER IN SOME CIRCUMSTANCES AND/OR NETWORK ENVIRONMENTS THE PRODUCT MAY NOT FUNCTION AS INTENDED.  THEREFORE, BY INSTALLING THE PRODUCT YOU UNDERSTAND AND AGREE THAT SUCH INSTALLATION WILL NOT BE ERROR FREE AND MAY RESULT IN CONNECTIVITY ISSUES AND/OR OTHER SUCH NETWORK INTERRUPTION, SUCH AS NETWORK SLOWDOWNS.  IN THE EVENT THAT YOU EXPERIENCE THESE ISSUES, YOU AGREE THAT YOUR SOLE REMEDY SHALL BE TO DISCONNECT AND DISCONTINUE YOUR USE OF THE PRODUCT, AS PROVIDED IN SECTION 9 ABOVE.',
      'THE PRODUCT IS PROVIDED "AS IS", “AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY REPRESENTATIONS OR WARRANTIES REGARDING ACCURACY, OPERABILITY, USE, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, ANY WARRANTY THAT ITS USE WILL PRODUCE ANY RESULTS, FINANCIAL OR OTHERWISE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. COASTFI, AND OUR AFFILIATES AND EACH OF OUR AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS, CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES DO NOT WARRANT THAT: (I) THE PRODUCT WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) THERE WILL BE NO INTERFERENCE WITH YOUR INTERNET SERVICE; OR (IV) THE RESULTS OF USING THE PRODUCT WILL MEET YOUR EXPECTATIONS. YOUR USE OF THE PRODUCT IS SOLELY AT YOUR OWN RISK.',
      'THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.'
    ])
  },
  {
    term: 'Limitation of Liability.',
    condition: wrapOL([
      'TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU AGREE THAT NEITHER COASTFI, NOR OUR AFFILIATES AND EACH OF OUR AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS, CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES WILL BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, SERVICE INTERFERENCE, DEVICE DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, OR FROM THE USE OF THE PRODUCT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT COASTFI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.',
      'TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU AGREE THAT UNDER NO CIRCUMSTANCES SHALL COASTFI, AND OUR AFFILIATES AND EACH OF OUR AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS, CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES BE LIABLE FOR ANY DAMAGES, OF ANY NATURE WHATSOEVER, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE PRODUCT, INCLUDING BUT NOT LIMITED TO, DAMAGES FROM BREACH OF PRIVACY OR CONFIDENTIALITY, OR RESULTING FROM DAMAGE TO YOUR NETWORK OR TO ANY DEVICES CONNECTED THERETO, OR FOR LOSS OF DATA.',
      'TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL THE TOTAL LIABILITY OF COASTFI, AND EACH OF OUR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR USE OF THE PRODUCT EXCEED FIVE HUNDRED DOLLARS ($500.00 USD) IN THE AGGREGATE. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN COASTFI AND YOU.',
      'THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.'
    ])
  },
  {
    term: 'Indemnification.',
    condition: wrapOL([
      'YOU SHALL DEFEND, INDEMNIFY, AND HOLD HARMLESS COASTFI, AND OUR AFFILIATES AND EACH OF OUR AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS, CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES FROM AND AGAINST ANY AND ALL ACTUAL OR THREATENED LIABILITIES, CLAIMS, AND EXPENSES, INCLUDING REASONABLE ATTORNEYS’ FEES, THAT ARISE FROM OR RELATE TO ANY BREACH BY YOU OF THESE TERMS, YOUR USE OR MISUSE OF THE PRODUCT, ANY ACT OR OMISSION BY YOU OR OF ANY OF YOUR PRINCIPALS, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AND REPRESENTATIVES (AS APPLICABLE), AND ANY CLAIM BY ANY THIRD PARTY RELATED TO YOUR PERFORMANCE OF ACTIVITIES IN CONNECTION WITH THESE TERMS. WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU WILL ASSIST AND COOPERATE WITH US IN ASSERTING ANY AVAILABLE DEFENSES.',
      'THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.'
    ])
  },
  {
    term: 'Governing Law, Jurisdiction and No Class Actions.',
    condition: wrapOL([
      '<strong>GOVERNING LAW. </strong>YOUR USE OF THE PRODUCT AND ANY DISPUTE ARISING OUT OF OR IN CONNECTION WITH THE PRODUCT INCLUDING THE TERMS SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF NEW YORK WITHOUT GIVING EFFECT TO ANY CONFLICT OF LAWS PROVISIONS.',
      '<strong>JURISDICTION. </strong>YOU AND WE AGREE THAT ANY SUIT, ACTION OR PROCEEDING SHALL BE BROUGHT IN THE STATE OF NEW YORK AND HEREBY IRREVOCABLY CONSENT TO BE SUBJECT TO THE PERSONAL JURISDICTION THEREOF AND WAIVE ANY CLAIM OF FORUM NON CONVENIENS OR LACK OF PERSONAL JURISDICTION THAT YOU OR WE MAY OTHERWISE HAVE.',
      '<strong>NO CLASS ACTIONS. </strong>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ANY AND ALL DISPUTES, CLAIMS AND CAUSES OF ACTION YOU MAY HAVE IN CONNECTION WITH OR RELATED TO THE PRODUCT, TO THE USE THEREOF, AND/OR TO THESE TERMS WILL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION. YOU SHALL NOT BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CONSUMERS OR LITIGATE, AS A REPRESENTATIVE OR MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY, ANY CLAIM, DISPUTE OR CAUSE OF ACTION IN CONNECTION WITH OR RELATED TO THE PRODUCT, TO THE USE THEREOF, AND/OR TO THESE TERMS.'
    ])
  },
  {
    term: 'Miscellaneous.',
    condition: wrapOL([
      '<strong>Entire Agreement. </strong>These Terms constitute the entire agreement between you and CoastFi governing your use of the Product, superseding any prior agreements between you and CoastFi with respect to the Product.',
      '<strong>Severability. </strong>The provisions of these Terms are intended to be interpreted in a manner which makes them valid, legal and enforceable. The failure of CoastFi to enforce any right or the provision in these Terms shall not constitute a waiver of such right or provision. In the event that any provision of these Terms is found to be partially or wholly invalid, illegal or unenforceable, such provision shall be modified or restricted to the extent and in the manner necessary to render it valid, legal and enforceable. It is expressly understood and agreed that such modification or restriction may be accomplished unilaterally by CoastFi, or alternatively, by disposition of an arbitrator or a court of law. If such provision cannot under any circumstances be so modified or restricted, it shall be excised from these Terms without affecting the validity, legality or enforceability of any of the remaining provisions.',
      '<strong>Force Majeure. </strong>CoastFi, nor any other party involved in creating, producing, or delivering the Product, shall be liable with respect to any damages, injuries, nonperformance or delay in performance by reason of any act of God, weather, fire, flood, acts of terror or foreign enemy, governmental order or regulation, trade dispute, or any other similar cause beyond its respective control.',
      '<strong>Notice. </strong>Any notices or other communications provided by CoastFi under these Terms will be given: (a) via email; or (b) by posting on our website. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted to any email address you provided.',
      '<strong>Waiver. </strong>CoastFi’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of CoastFi. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.',
      '<strong>Assignment. </strong>These Terms are not assignable, transferable or sublicensable by you except with our prior written consent. You may not assign or transfer these Terms, by operation of law or otherwise, without CoastFi’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, is void. CoastFi may freely assign or transfer these Terms without restriction, and the transferor or assignor shall not remain jointly and severally liable. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.',
      '<strong>Headings. </strong>The section and paragraph headings in these Terms are for convenience only and shall not affect their interpretation.',
      '<strong>Agency. </strong>No agency, partnership, joint venture, or employment relationship is created as a result of these Terms and neither party has any authority of any kind to bind the other in any respect.',
      '<strong>Electronic Communications. </strong>When you send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on our website. You agree that all agreements, notices, disclosures and other communications that we provide you electronically satisfy any legal requirement that such communications be in writing.',
      '<strong>Contact Information. </strong>If you have any questions about these Terms or the Product, please contact CoastFi at help@coastfi.com.'
    ])
  }
];
