export default [
    {
        path: '/chicago',
        name: 'Chicago'
    },
    {
        path: '/syosset',
        name: 'Syosset'
    },
    {
        path: '/hicksville',
        name: 'Hicksville'
    },
    {
        path: '/boston',
        name: 'Boston'
    },
    {
        path: '/dallas',
        name: 'Dallas'
    },
    {
        path: '/plainview',
        name: 'Plainview'
    },
    {
        path: '/pittsburgh',
        name: 'Pittsburgh'
    },
    {
        name: 'Stonington',
        path: '/stonington'
    },
    {
        name: 'Marquand',
        path: '/marquand'
    },
    {
        name: 'Nashville',
        path: '/nashville'
    },
    {
        name: 'Hoboken',
        path: '/hoboken'
    },
    {
        name: 'Farmingdale',
        path: '/farmingdale'
    },
    {
        name: 'Columbia',
        path: '/columbia'
    },
    {
        name: 'Guttenberg',
        path: '/guttenberg'
    },
    {
        name: 'Verona',
        path: '/verona'
    },
    {
        name: 'Marina del Rey',
        path: '/marina-del-rey'
    },
    {
        name: 'Spring Lake',
        path: '/spring-lake'
    },
    {
        name: 'San Francisco',
        path: '/san-francisco'
    },
    {
        name: 'Modesto',
        path: '/modesto'
    },
    {
        name: 'Brooklyn',
        path: '/brooklyn'
    },
    {
        name: 'Hillside',
        path: '/hillside'
    },
    {
        name: 'Bryn Mawr',
        path: '/bryn-mawr'
    },
    {
        name: 'McDonald',
        path: '/mcdonald'
    },
    {
        name: 'McKees Rocks',
        path: '/mckees-rocks'
    },
    {
        name: 'Cliffside Park',
        path: '/cliffside-park'
    },
    {
        name: 'Edison',
        path: '/edison'
    },
    {
        name: 'Downingtown',
        path: '/downingtown'
    },
    {
        name: 'Cape May Point',
        path: '/cape-may-point'
    },
    {
        name: 'Kenilworth',
        path: '/kenilworth'
    },
    {
        name: 'Superior',
        path: '/superior'
    },
    {
        name: 'East Pittsburgh',
        path: '/east-pittsburgh'
    },
    {
        name: 'Woodbridge',
        path: '/woodbridge'
    },
    {
        name: 'Avondale Estates',
        path: '/avondale-estates'
    },
    {
        name: 'Saint Francisville',
        path: '/saint-francisville'
    },
    {
        name: 'Seaside Heights',
        path: '/seaside-heights'
    },
    {
        name: 'Belmar',
        path: '/belmar'
    },
    {
        name: 'Avon-by-the-Sea',
        path: '/avon-by-the-sea'
    },
    {
        name: 'Roslyn',
        path: '/roslyn'
    },
    {
        name: 'Creekside',
        path: '/creekside'
    },
    {
        name: 'Greenwood Village',
        path: '/greenwood-village'
    },
    {
        name: 'Spring Valley',
        path: '/spring-valley'
    },
    {
        name: 'Wildwood Crest',
        path: '/wildwood-crest'
    },
    {
        name: 'Minneapolis',
        path: '/minneapolis'
    },
    {
        name: 'Chappaqua',
        path: '/chappaqua'
    },
    {
        name: 'Munster',
        path: '/munster'
    },
    {
        name: 'West Chester',
        path: '/west-chester'
    },
    {
        name: 'Oakdale',
        path: '/oakdale'
    },
    {
        name: 'State College',
        path: '/state-college'
    },
    {
        name: 'Weehawken',
        path: '/weehawken'
    },
    {
        name: 'Malvern',
        path: '/malvern'
    },
    {
        name: 'Galway',
        path: '/galway'
    },
    {
        name: 'South Orange',
        path: '/south-orange'
    },
    {
        name: 'Port Huron',
        path: '/port-huron'
    },
    {
        name: 'Townsend',
        path: '/townsend'
    },
    {
        name: 'Cambridge',
        path: '/cambridge'
    },
    {
        name: 'Braddock',
        path: '/braddock'
    },
    {
        name: 'Williamstown',
        path: '/williamstown'
    },
    {
        name: 'Hermosa Beach',
        path: '/hermosa-beach'
    },
    {
        name: 'Somerville',
        path: '/somerville'
    },
    {
        name: 'Sussex',
        path: '/sussex'
    },
    {
        name: 'Cape May',
        path: '/cape-may'
    },
    {
        name: 'Ewa Beach',
        path: '/ewa-beach'
    },
    {
        name: 'Key West',
        path: '/key-west'
    },
    {
        name: 'Great Neck Plaza',
        path: '/great-neck-plaza'
    },
    {
        name: 'Hatfield',
        path: '/hatfield'
    },
    {
        name: 'Union City',
        path: '/union-city'
    },
    {
        name: 'Manchaca',
        path: '/manchaca'
    },
    {
        name: 'Waverly',
        path: '/waverly'
    },
    {
        name: 'New London',
        path: '/new-london'
    },
    {
        name: 'Miami',
        path: '/miami'
    },
    {
        name: 'Turlock',
        path: '/turlock'
    },
    {
        name: 'Elizabeth',
        path: '/elizabeth'
    },
    {
        name: 'West Miami',
        path: '/west-miami'
    },
    {
        name: 'Decatur',
        path: '/decatur'
    },
    {
        name: 'Brentwood',
        path: '/brentwood'
    },
    {
        name: 'Longport',
        path: '/longport'
    },
    {
        name: 'Rumson',
        path: '/rumson'
    },
    {
        name: 'Margate City',
        path: '/margate-city'
    },
    {
        name: 'Falls Church',
        path: '/falls-church'
    },
    {
        name: 'Jersey City',
        path: '/jersey-city'
    },
    {
        name: 'Bemus Point',
        path: '/bemus-point'
    },
    {
        name: 'Long Beach',
        path: '/long-beach'
    },
    {
        name: 'Edgewater',
        path: '/edgewater'
    },
    {
        name: 'West Hollywood',
        path: '/west-hollywood'
    },
    {
        name: 'Berkeley',
        path: '/berkeley'
    },
    {
        name: 'Manhasset',
        path: '/manhasset'
    },
    {
        name: 'Boulder',
        path: '/boulder'
    },
    {
        name: 'Hastings-on-Hudson',
        path: '/hastings-on-hudson'
    },
    {
        name: 'Menlo Park',
        path: '/menlo-park'
    },
    {
        name: 'Havre',
        path: '/havre'
    },
    {
        name: 'Louisville',
        path: '/louisville'
    },
    {
        name: 'Syosset',
        path: '/syosset'
    },
    {
        name: 'University Park',
        path: '/university-park'
    },
    {
        name: 'Cumming',
        path: '/cumming'
    },
    {
        name: 'Wendell',
        path: '/wendell'
    },
    {
        name: 'Hackensack',
        path: '/hackensack'
    },
    {
        name: 'Santa Monica',
        path: '/santa-monica'
    },
    {
        name: 'Watertown',
        path: '/watertown'
    },
    {
        name: 'Montrose',
        path: '/montrose'
    },
    {
        name: 'Bridgeville',
        path: '/bridgeville'
    },
    {
        name: 'Fennville',
        path: '/fennville'
    },
    {
        name: 'Caseyville',
        path: '/caseyville'
    },
    {
        name: 'Pittsburgh',
        path: '/pittsburgh'
    },
    {
        name: 'Hull',
        path: '/hull'
    },
    {
        name: 'El Monte',
        path: '/el-monte'
    },
    {
        name: 'Munhall',
        path: '/munhall'
    },
    {
        name: 'Homestead',
        path: '/homestead'
    },
    {
name: 'Queens',
path: '/queens'
},
{
name: 'Huntington Park',
path: '/huntington-park'
},
{
name: 'Saint Clair',
path: '/saint-clair'
},
{
name: 'Leesburg',
path: '/leesburg'
},
{
name: 'Cupertino',
path: '/cupertino'
},
{
name: 'Passaic',
path: '/passaic'
},
{
name: 'Littleton',
path: '/littleton'
},
{
name: 'Berkley',
path: '/berkley'
},
{
name: 'Hawthorne',
path: '/hawthorne'
},
{
name: 'Lakewood',
path: '/lakewood'
},
{
name: 'Woodbury',
path: '/woodbury'
},
{
name: 'Fitchburg',
path: '/fitchburg'
},
{
name: 'Gillette',
path: '/gillette'
},
{
name: 'New Haven',
path: '/new-haven'
},
{
name: 'Chicago',
path: '/chicago'
},
{
name: 'Bayonne',
path: '/bayonne'
},
{
name: 'Ann Arbor',
path: '/ann-arbor'
},
{
name: 'Goodland',
path: '/goodland'
},
{
name: 'Boscobel',
path: '/boscobel'
},
{
name: 'New York',
path: '/new-york'
},
{
name: 'Maryville',
path: '/maryville'
},
{
name: 'Belmont',
path: '/belmont'
},
{
name: 'Fairfax',
path: '/fairfax'
},
{
name: 'Dearborn Heights',
path: '/dearborn-heights'
},
{
name: 'Manhattan Beach',
path: '/manhattan-beach'
},
{
name: 'Dearborn',
path: '/dearborn'
},
{
name: 'Los Altos',
path: '/los-altos'
},
{
name: 'Cranford',
path: '/cranford'
},
{
name: 'Collinsville',
path: '/collinsville'
},
{
name: 'Greenwich',
path: '/greenwich'
},
{
name: 'Helotes',
path: '/helotes'
},
{
name: 'Duarte',
path: '/duarte'
},
{
name: 'Kirkland',
path: '/kirkland'
},
{
name: 'Vestavia Hills',
path: '/vestavia-hills'
},
{
name: 'Daly City',
path: '/daly-city'
},
{
name: 'Mountain View',
path: '/mountain-view'
},
{
name: 'Spring',
path: '/spring'
},
{
name: 'Boston',
path: '/boston'
},
{
name: 'Seal Beach',
path: '/seal-beach'
},
{
name: 'Vienna',
path: '/vienna'
},
{
name: 'Suwanee',
path: '/suwanee'
},
{
name: 'San Carlos',
path: '/san-carlos'
},
{
name: 'Fayetteville',
path: '/fayetteville'
},
{
name: 'Milpitas',
path: '/milpitas'
},
{
name: 'Seattle',
path: '/seattle'
},
{
name: 'The Bronx',
path: '/the-bronx'
},
{
name: 'Union',
path: '/union'
},
{
name: 'San Mateo',
path: '/san-mateo'
},
{
name: 'Portola Valley',
path: '/portola-valley'
},
{
name: 'Naples',
path: '/naples'
},
{
name: 'Redondo Beach',
path: '/redondo-beach'
},
{
name: 'Livermore',
path: '/livermore'
},
{
name: 'Washington',
path: '/washington'
},
{
name: 'Lafayette',
path: '/lafayette'
},
{
name: 'Teaneck',
path: '/teaneck'
},
{
name: 'Imperial Beach',
path: '/imperial-beach'
},
{
name: 'Prospect Heights',
path: '/prospect-heights'
},
{
name: 'Scituate',
path: '/scituate'
},
{
name: 'Alpharetta',
path: '/alpharetta'
},
{
name: 'Tombstone',
path: '/tombstone'
},
{
name: 'Wilmette',
path: '/wilmette'
},
{
name: 'Gainesville',
path: '/gainesville'
},
{
name: 'San Rafael',
path: '/san-rafael'
},
{
name: 'Edmonds',
path: '/edmonds'
},
{
name: 'Newton',
path: '/newton'
},
{
name: 'Silver Spring',
path: '/silver-spring'
},
{
name: 'Arlington',
path: '/arlington'
},
{
name: 'Golden Valley',
path: '/golden-valley'
},
{
name: 'Plainview',
path: '/plainview'
},
{
name: 'Medford',
path: '/medford'
},
{
name: 'Covina',
path: '/covina'
},
{
name: 'Dublin',
path: '/dublin'
},
{
name: 'Encinitas',
path: '/encinitas'
},
{
name: 'Redwood City',
path: '/redwood-city'
},
{
name: 'Bellevue',
path: '/bellevue'
},
{
name: 'Grovetown',
path: '/grovetown'
},
{
name: 'Norcross',
path: '/norcross'
},
{
name: 'Dacula',
path: '/dacula'
},
{
name: 'Katy',
path: '/katy'
},
{
name: 'McKeesport',
path: '/mckeesport'
},
{
name: 'Middletown',
path: '/middletown'
},
{
name: 'Culver City',
path: '/culver-city'
},
{
name: 'Glendora',
path: '/glendora'
},
{
name: 'Evanston',
path: '/evanston'
},
{
name: 'Hollywood',
path: '/hollywood'
},
{
name: 'Englewood',
path: '/englewood'
},
{
name: 'Cypress',
path: '/cypress'
},
{
name: 'Denver',
path: '/denver'
},
{
name: 'Philadelphia',
path: '/philadelphia'
},
{
name: 'Harrisburg',
path: '/harrisburg'
},
{
name: 'Pleasant Hill',
path: '/pleasant-hill'
},
{
name: 'Santa Clara',
path: '/santa-clara'
},
{
name: 'Annapolis',
path: '/annapolis'
},
{
name: 'Leominster',
path: '/leominster'
},
{
name: 'Big Pine Key',
path: '/big-pine-key'
},
{
name: 'Arroyo Grande',
path: '/arroyo-grande'
},
{
name: 'Fort Gratiot Township',
path: '/fort-gratiot-township'
},
{
name: 'Sunnyvale',
path: '/sunnyvale'
},
{
name: 'Austin',
path: '/austin'
},
{
name: 'Atlanta',
path: '/atlanta'
},
    {
name: 'Houston',
path: '/houston'
},
{
name: 'Royal Oak',
path: '/royal-oak'
},
{
name: 'Burbank',
path: '/burbank'
},
{
name: 'West New York',
path: '/west-new-york'
},
{
name: 'Neptune City',
path: '/neptune-city'
},
{
name: 'Roselle Park',
path: '/roselle-park'
},
{
name: 'Artesia',
path: '/artesia'
},
{
name: 'Atlantic Highlands',
path: '/atlantic-highlands'
},
{
name: 'Plantation',
path: '/plantation'
},
{
name: 'Albany',
path: '/albany'
},
{
name: 'Stanton',
path: '/stanton'
},
{
name: 'North Bergen',
path: '/north-bergen'
},
{
name: 'Fort Lee',
path: '/fort-lee'
},
{
name: 'Sea Girt',
path: '/sea-girt'
},
{
name: 'Maplewood',
path: '/maplewood'
},
{
name: 'Sharon Hill',
path: '/sharon-hill'
},
{
name: 'Coraopolis',
path: '/coraopolis'
},
{
name: 'East Palo Alto',
path: '/east-palo-alto'
},
{
name: 'Whitefish Bay',
path: '/whitefish-bay'
},
{
name: 'Brookline',
path: '/brookline'
},
{
name: 'Yeadon',
path: '/yeadon'
},
{
name: 'Hiawassee',
path: '/hiawassee'
},
{
name: 'Phoenixville',
path: '/phoenixville'
},
{
name: 'Alexandria',
path: '/alexandria'
},
{
name: 'Alhambra',
path: '/alhambra'
},
{
name: 'Gardena',
path: '/gardena'
},
{
name: 'Highland',
path: '/highland'
},
{
name: 'Los Angeles',
path: '/los-angeles'
},
{
name: 'Alameda',
path: '/alameda'
},
{
name: 'Baltimore',
path: '/baltimore'
},
{
name: 'El Cerrito',
path: '/el-cerrito'
},
{
name: 'South San Francisco',
path: '/south-san-francisco'
},
{
name: 'Hopkins',
path: '/hopkins'
},
{
name: 'South Gate',
path: '/south-gate'
},
{
name: 'Metuchen',
path: '/metuchen'
},
{
name: 'Arcadia',
path: '/arcadia'
},
{
name: 'Newark',
path: '/newark'
},
{
name: 'South El Monte',
path: '/south-el-monte'
},
{
name: 'Centennial',
path: '/centennial'
},
{
name: 'Hallandale Beach',
path: '/hallandale-beach'
},
{
name: 'Mountain House',
path: '/mountain-house'
},
{
name: 'Oakland',
path: '/oakland'
},
{
name: 'East Lansing',
path: '/east-lansing'
},
{
name: 'Bellflower',
path: '/bellflower'
},
{
name: 'Stanford',
path: '/stanford'
},
{
name: 'College Park',
path: '/college-park'
},
{
name: 'Little Silver',
path: '/little-silver'
},
{
name: 'Piedmont',
path: '/piedmont'
},
{
name: 'Portland',
path: '/portland'
},
{
name: 'Westover',
path: '/westover'
},
{
name: 'Windermere',
path: '/windermere'
},
{
name: 'San Jose',
path: '/san-jose'
},
{
name: 'St Louis',
path: '/st-louis'
},
{
name: 'Carrollton',
path: '/carrollton'
},
{
name: 'Wheaton',
path: '/wheaton'
},
{
name: 'Duquesne',
path: '/duquesne'
},
{
name: 'Champaign',
path: '/champaign'
},
{
name: 'Nashua',
path: '/nashua'
},
{
name: 'Napa',
path: '/napa'
},
{
name: 'Aliso Viejo',
path: '/aliso-viejo'
},
{
name: 'Salem',
path: '/salem'
},
{
name: 'Burlingame',
path: '/burlingame'
},
{
name: 'Wildwood',
path: '/wildwood'
},
{
name: 'Bellport',
path: '/bellport'
},
{
name: 'Downey',
path: '/downey'
},
{
name: 'Dyer',
path: '/dyer'
},
{
name: 'Safety Harbor',
path: '/safety-harbor'
},
{
name: 'San Bruno',
path: '/san-bruno'
},
{
name: 'Reston',
path: '/reston'
},
{
name: 'Plainfield',
path: '/plainfield'
},
{
name: 'Torrance',
path: '/torrance'
},
{
name: 'Ceres',
path: '/ceres'
},
{
name: 'Bothell',
path: '/bothell'
},
{
name: 'Westfield',
path: '/westfield'
},
{
name: 'Morrisville',
path: '/morrisville'
},
{
name: 'Herndon',
path: '/herndon'
},
{
name: 'Springfield',
path: '/springfield'
},
{
name: 'Smyrna',
path: '/smyrna'
},
{
name: 'San Leandro',
path: '/san-leandro'
},
{
name: 'Hartford',
path: '/hartford'
},
{
name: 'Santa Barbara',
path: '/santa-barbara'
},
{
name: 'Palos Verdes Estates',
path: '/palos-verdes-estates'
},
{
name: 'Altadena',
path: '/altadena'
},
{
name: 'Newcastle',
path: '/newcastle'
},
{
name: 'Havertown',
path: '/havertown'
},
{
name: 'Clearwater',
path: '/clearwater'
},
{
name: 'La Mesa',
path: '/la-mesa'
},
{
name: 'Westminster',
path: '/westminster'
},
{
name: 'Knightdale',
path: '/knightdale'
},
{
name: 'Hammond',
path: '/hammond'
},
{
name: 'Swansea',
path: '/swansea'
},
{
name: 'St Louis',
path: '/st-louis'
},
{
name: 'Exton',
path: '/exton'
},
{
name: 'Lansing',
path: '/lansing'
},
{
name: 'Bloomington',
path: '/bloomington'
},
{
name: 'Beaverton',
path: '/beaverton'
},
{
name: 'Fremont',
path: '/fremont'
},
{
name: 'West Springfield',
path: '/west-springfield'
},
{
name: 'Aston',
path: '/aston'
},
{
name: 'Compton',
path: '/compton'
},
{
name: 'Glendale',
path: '/glendale'
},
{
name: 'Groton',
path: '/groton'
},
{
name: 'Carol Stream',
path: '/carol-stream'
},
{
name: 'Chamblee',
path: '/chamblee'
},
{
name: 'Braddock Hills',
path: '/braddock-hills'
},
{
name: 'Eagle Pass',
path: '/eagle-pass'
},
{
name: 'Bellefonte',
path: '/bellefonte'
},
{
name: 'Port Washington',
path: '/port-washington'
},
{
name: 'San Diego',
path: '/san-diego'
},
{
name: 'Anaheim',
path: '/anaheim'
},
{
name: 'Redmond',
path: '/redmond'
},
{
name: 'Marietta',
path: '/marietta'
},
{
name: 'Buffalo',
path: '/buffalo'
},
{
name: 'Longmont',
path: '/longmont'
},
{
name: 'Ashburn',
path: '/ashburn'
},
{
name: 'Las Vegas',
path: '/las-vegas'
},
{
name: 'Palo Alto',
path: '/palo-alto'
},
{
name: 'Canton',
path: '/canton'
},
{
name: 'Fort Lauderdale',
path: '/fort-lauderdale'
},
{
name: 'Garden Grove',
path: '/garden-grove'
},
{
name: 'New Kensington',
path: '/new-kensington'
},
{
name: 'Cleveland',
path: '/cleveland'
},
{
name: 'La Mirada',
path: '/la-mirada'
},
{
name: 'Scottdale',
path: '/scottdale'
},
{
name: 'Pasadena',
path: '/pasadena'
},
{
name: 'Azusa',
path: '/azusa'
},
{
name: 'Waterford',
path: '/waterford'
},
{
name: 'Sugar Land',
path: '/sugar-land'
},
{
name: 'Sacramento',
path: '/sacramento'
},
{
name: 'Wheeling',
path: '/wheeling'
},
{
name: 'Homewood',
path: '/homewood'
},
{
name: 'Brisbane',
path: '/brisbane'
},
{
name: 'Bellaire',
path: '/bellaire'
},
{
name: 'Santa Maria',
path: '/santa-maria'
},
{
name: 'Middleton',
path: '/middleton'
},
{
name: 'Quincy',
path: '/quincy'
},
{
name: 'Erie',
path: '/erie'
},
{
name: 'Tempe',
path: '/tempe'
},
{
name: 'Vallejo',
path: '/vallejo'
},
{
name: 'Athens',
path: '/athens'
},
{
name: 'West Covina',
path: '/west-covina'
},
{
name: 'Los Gatos',
path: '/los-gatos'
},
{
name: 'Cottonwood Heights',
path: '/cottonwood-heights'
},
{
name: 'Irvine',
path: '/irvine'
},
{
name: 'Laguna Beach',
path: '/laguna-beach'
},
{
name: 'Vista',
path: '/vista'
},
{
name: 'Bentonville',
path: '/bentonville'
},
{
name: 'Quinnesec',
path: '/quinnesec'
},
{
name: 'Arlington Heights',
path: '/arlington-heights'
},
{
name: 'Troy',
path: '/troy'
},
{
name: 'Naperville',
path: '/naperville'
},
{
name: 'Millcreek',
path: '/millcreek'
},
{
name: 'Pleasanton',
path: '/pleasanton'
},
{
name: 'Edina',
path: '/edina'
},
{
name: 'Madison',
path: '/madison'
},
{
name: 'Walnut Creek',
path: '/walnut-creek'
},
{
name: 'South Lake Tahoe',
path: '/south-lake-tahoe'
},
{
name: 'Euless',
path: '/euless'
},
{
name: 'Taylorsville',
path: '/taylorsville'
},
{
name: 'Dickinson',
path: '/dickinson'
},
{
name: 'Oviedo',
path: '/oviedo'
},
{
name: 'Billings',
path: '/billings'
},
{
name: 'Plano',
path: '/plano'
},
{
name: 'San Antonio',
path: '/san-antonio'
},
{
name: 'Murray',
path: '/murray'
},
{
name: 'Staten Island',
path: '/staten-island'
},
{
name: 'Wayzata',
path: '/wayzata'
},
{
name: 'South Jordan',
path: '/south-jordan'
},
{
name: 'O\'Fallon',
path: '/o-fallon'
},
{
name: 'Newport Beach',
path: '/newport-beach'
},
{
name: 'Morgantown',
path: '/morgantown'
},
{
name: 'Apple Valley',
path: '/apple-valley'
},
{
name: 'Lake Forest',
path: '/lake-forest'
},
{
name: 'Frisco',
path: '/frisco'
},
{
name: 'Cincinnati',
path: '/cincinnati'
},
{
name: 'Rogers',
path: '/rogers'
},
{
name: 'Hacienda Heights',
path: '/hacienda-heights'
},
{
name: 'Tracy',
path: '/tracy'
},
{
name: 'Tampa',
path: '/tampa'
},
{
name: 'Atherton',
path: '/atherton'
},
{
name: 'Hayward',
path: '/hayward'
},
{
name: 'Santa Rosa',
path: '/santa-rosa'
},
{
name: 'Scarsdale',
path: '/scarsdale'
},
{
name: 'Orlando',
path: '/orlando'
},
{
name: 'Coppell',
path: '/coppell'
},
{
name: 'Westland',
path: '/westland'
},
{
name: 'St Augustine',
path: '/st-augustine'
},
{
name: 'West Cape May',
path: '/west-cape-may'
},
{
name: 'West Lake Hills',
path: '/west-lake-hills'
},
{
name: 'Birmingham',
path: '/birmingham'
},
{
name: 'Baton Rouge',
path: '/baton-rouge'
},
{
name: 'Oceanside',
path: '/oceanside'
},
{
name: 'Laguna Hills',
path: '/laguna-hills'
},
{
name: 'Milwaukee',
path: '/milwaukee'
},
{
name: 'Grapevine',
path: '/grapevine'
},
{
name: 'Pinecrest',
path: '/pinecrest'
},
{
name: 'Hillsboro',
path: '/hillsboro'
},
{
name: 'Sandy',
path: '/sandy'
},
{
name: 'Martinez',
path: '/martinez'
},
{
name: 'St Petersburg',
path: '/st-petersburg'
},
{
name: 'Schererville',
path: '/schererville'
},
{
name: 'Springdale',
path: '/springdale'
},
{
name: 'Mission Viejo',
path: '/mission-viejo'
},
{
name: 'Mesa',
path: '/mesa'
},
{
name: 'Santa Clarita',
path: '/santa-clarita'
},
{
name: 'Laguna Niguel',
path: '/laguna-niguel'
},
{
name: 'Marco Island',
path: '/marco-island'
},
{
name: 'Broomfield',
path: '/broomfield'
},
{
name: 'Villas',
path: '/villas'
},
{
name: 'Sioux Falls',
path: '/sioux-falls'
},
{
name: 'Roswell',
path: '/roswell'
},
{
name: 'Arvada',
path: '/arvada'
},
{
name: 'Rancho Cucamonga',
path: '/rancho-cucamonga'
},
{
name: 'Dayton',
path: '/dayton'
},
{
name: 'Breckenridge',
path: '/breckenridge'
},
{
name: 'Columbus',
path: '/columbus'
},
{
name: 'Stone Harbor',
path: '/stone-harbor'
},
{
name: 'Glen Carbon',
path: '/glen-carbon'
},
{
name: 'Minnetonka',
path: '/minnetonka'
},
{
name: 'Plymouth',
path: '/plymouth'
},
{
name: 'Fairfield',
path: '/fairfield'
},
{
name: 'Stevenson Ranch',
path: '/stevenson-ranch'
},
{
name: 'West Linn',
path: '/west-linn'
},
{
name: 'Richmond',
path: '/richmond'
},
{
name: 'Carlsbad',
path: '/carlsbad'
},
{
name: 'Rancho Palos Verdes',
path: '/rancho-palos-verdes'
},
{
name: 'Elk Grove',
path: '/elk-grove'
},
{
name: 'Lakeside',
path: '/lakeside'
},
{
name: 'Independence',
path: '/independence'
},
{
name: 'Aurora',
path: '/aurora'
},
{
name: 'Roseville',
path: '/roseville'
},
{
name: 'Chico',
path: '/chico'
},
{
name: 'Thornton',
path: '/thornton'
},
{
name: 'Corona de Tucson',
path: '/corona-de-tucson'
},
{
name: 'Manchester',
path: '/manchester'
},
{
name: 'Monrovia',
path: '/monrovia'
},
{
name: 'Hamden',
path: '/hamden'
},
{
name: 'Starkville',
path: '/starkville'
},
{
name: 'Rochester',
path: '/rochester'
},
{
name: 'Steamboat Springs',
path: '/steamboat-springs'
},
{
name: 'Lincoln',
path: '/lincoln'
},
{
name: 'White Oak',
path: '/white-oak'
},
{
name: 'Santa Fe',
path: '/santa-fe'
},
{
name: 'Westerly',
path: '/westerly'
},
{
name: 'Riverside',
path: '/riverside'
},
{
name: 'Alton',
path: '/alton'
},
{
name: 'Belleville',
path: '/belleville'
},
{
name: 'Evans',
path: '/evans'
},
{
name: 'Edwardsville',
path: '/edwardsville'
},
{
name: 'Huntsville',
path: '/huntsville'
},
{
name: 'Irving',
path: '/irving'
},
{
name: 'New Braunfels',
path: '/new-braunfels'
},
{
name: 'Draper',
path: '/draper'
},
{
name: 'Boise',
path: '/boise'
},
{
name: 'Novato',
path: '/novato'
},
{
name: 'The Woodlands',
path: '/the-woodlands'
},
{
name: 'Sandy Springs',
path: '/sandy-springs'
},
{
name: 'Chino',
path: '/chino'
},
{
name: 'Oxnard',
path: '/oxnard'
},
{
name: 'Raleigh',
path: '/raleigh'
},
{
name: 'Lunenburg',
path: '/lunenburg'
},
{
name: 'Waterbury',
path: '/waterbury'
},
{
name: 'Hopatcong',
path: '/hopatcong'
},
{
name: 'Little Rock',
path: '/little-rock'
},
{
name: 'Tomball',
path: '/tomball'
},
{
name: 'Overland Park',
path: '/overland-park'
},
{
name: 'Glenmoore',
path: '/glenmoore'
},
{
name: 'Bakersfield',
path: '/bakersfield'
},
{
name: 'New Orleans',
path: '/new-orleans'
},
{
name: 'Danville',
path: '/danville'
},
{
name: 'Malibu',
path: '/malibu'
},
{
name: 'Maple Grove',
path: '/maple-grove'
},
{
name: 'East Haven',
path: '/east-haven'
},
{
name: 'Charlotte',
path: '/charlotte'
},
{
name: 'Vail',
path: '/vail'
},
{
name: 'Bonita Springs',
path: '/bonita-springs'
},
{
name: 'Cutchogue',
path: '/cutchogue'
},
{
name: 'Portage',
path: '/portage'
},
{
name: 'Colorado Springs',
path: '/colorado-springs'
},
{
name: 'Chino Hills',
path: '/chino-hills'
},
{
name: 'Detroit',
path: '/detroit'
},
{
name: 'Phoenix',
path: '/phoenix'
},
{
name: 'Cape Coral',
path: '/cape-coral'
},
{
name: 'Brady',
path: '/brady'
},
{
name: 'Wright',
path: '/wright'
},
{
name: 'Indianapolis',
path: '/indianapolis'
},
{
name: 'Woodside',
path: '/woodside'
},
{
name: 'Pontoon Beach',
path: '/pontoon-beach'
},
{
name: 'Clarkdale',
path: '/clarkdale'
},
{
name: 'Hudson',
path: '/hudson'
},
{
name: 'Tucson',
path: '/tucson'
},
{
name: 'Rome',
path: '/rome'
},
{
name: 'Livonia',
path: '/livonia'
},
{
name: 'Truth or Consequences',
path: '/truth-or-consequences'
},
{
name: 'Grand Rapids',
path: '/grand-rapids'
},
{
name: 'Port Arthur',
path: '/port-arthur'
},
{
name: 'Augusta',
path: '/augusta'
},
{
name: 'Topanga',
path: '/topanga'
},
{
name: 'Seguin',
path: '/seguin'
},
{
name: 'Fort Worth',
path: '/fort-worth'
},
{
name: 'Maryland Heights',
path: '/maryland-heights'
},
{
name: 'Davenport',
path: '/davenport'
},
{
name: 'Lebanon',
path: '/lebanon'
},
{
name: 'Griswold',
path: '/griswold'
},
{
name: 'Memphis',
path: '/memphis'
},
{
name: 'Hoover',
path: '/hoover'
},
{
name: 'North Adams',
path: '/north-adams'
},
{
name: 'Presque Isle',
path: '/presque-isle'
},
{
name: 'Canyon Lake',
path: '/canyon-lake'
},
{
name: 'Anchorage',
path: '/anchorage'
}
]
