import React from "react";
import StyledButton from "../styles/StyledButton";

interface IButton{
  size?: "normal" | "small"
  className?: string
  type?: "button" | "submit" | "reset" | undefined
  onClick?(e: React.MouseEvent): void
  disabled?: boolean
  children: React.ReactNode
}

const Button = ({
  size = "normal",
  className = "",
  type = "button",
  onClick = undefined,
  disabled = false,
  children
}: IButton) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className}
      size={size}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
