import React, { useState, useEffect } from "react";
import _ from 'lodash';
import StyledHeader, { StyledHamburger } from "../styles/StyledHeader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import Button from "./Button";

import cityRoutes from '../data/cityRoutes';

function Header(){
  const [isClicked, setIsClicked] = useState(false);
  const [home, setHome] = useState('/');

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(['/', '/it-installers', '/ambassador'].includes(pathname) || _.find(cityRoutes, {path: pathname})){
      setHome(pathname);
    }
  }, [pathname])

  const handleClick = (e: React.MouseEvent, destId?: string) => {
    if(!destId){
      return;
    }

    let timeout = 0;
    const dest = document.getElementById(destId);
    
    e.preventDefault();

    if(!dest && pathname !== home){
      history.push(home);
      timeout = 300;
    }

    setTimeout(() => {
      const dest = document.getElementById(destId);

      if(!dest){
        return;
      }

      window.scrollTo(
        0,
        dest.offsetTop + (destId === "contact" ? 100 : 0)
      );
      setIsClicked(false);
    }, timeout);
  };

  return (
    <StyledHeader className={`${isClicked ? "active" : ""}`}>
      <div className="container">
        <Link to={home} className="logo">
          <LogoIcon />
        </Link>
        <div className={`nav__links ${isClicked ? "clicked" : ""}`}>
          <span onClick={e => handleClick(e, 'how-it-works')}>
            How It Works
          </span>
          <span onClick={e => handleClick(e, 'give-100')}>
            Give $100, Get $100
          </span>
          <span onClick={e => handleClick(e, 'faq')}>
            FAQ
          </span>
          <span style={{marginRight: 0}} onClick={handleClick}>
            <a href="tel:+18882627881">888 - COAST81</a>
          </span>
          <Button onClick={e => handleClick(e, 'contact')} size="small">
            Easy Apply
          </Button>
        </div>
        <StyledHamburger
          className="hamburger-container"
          onClick={() => setIsClicked(!isClicked)}
        >
          <div className={`hamburger ${isClicked ? "clicked" : ""}`}></div>
        </StyledHamburger>
      </div>
    </StyledHeader>
  );
};

export default Header;
