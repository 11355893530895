import styled from "styled-components";

export default styled.header`
  padding: 3.2rem 1.6rem;
  min-height: 10.6rem;
  font-weight: 500;
  position: relative;
  z-index: 1;

  & .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.6s ease-out;

    & a,
    span {
      cursor: pointer;
      margin-right: 3.2rem;
    }

    & .hamburger-container {
      display: none;
    }
  }

  @media screen and (min-width: 901px) and (max-width: 1080px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 900px) {
    padding: 3.2rem;
    min-height: fit-content;
    &.active {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;

      & ~ main {
        padding-top: 10.4rem;
      }

      & .logo,
      & .hamburger-container {
        z-index: 2;
      }
    }

    & .container {
      & .hamburger-container {
        display: flex;
      }

      & .nav__links {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        clip-path: circle(100px at 90% -10%);
        transition: all 0.2s;
        opacity: 0;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
        max-height: 100%;
        min-width: 100vw;

        &.clicked {
          clip-path: circle(400vh at 90% -10%);
          opacity: 1;
          pointer-events: all;
          background: white;
        }

        & span {
          &:first-child {
            margin-top: 19.6rem;
          }
          margin-bottom: 4rem;
          font-size: 2.4rem;
          font-weight: 600;
        }

        & a,
        span {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 710px) {
    padding: 1.2rem 1.6rem;

    &.active {
      & ~ main {
        padding-top: 6.4rem;
      }
    }
  }
`;

export const StyledHamburger = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & .hamburger {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: #0f265a;
    height: 0.25rem;
    width: 2.2rem;
    transition: transform 0.4s;

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 2.8rem;
      height: 0.25rem;
      background: #0f265a;
      transition: transform 0.4s;
    }

    &::before {
      top: 0.7rem;
    }

    &::after {
      top: -0.7rem;
    }

    &.clicked {
      background: transparent;
      &::before,
      &::after {
        top: 0;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
`;
