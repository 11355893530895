export default [
  {
    term: "Products",
    condition: `<p>Helium Systems, Inc. is the manufacturer of the Product and the operator of the associated services and your use thereof are subject at all times to the Helium Terms and Conditions (<a href="https://www.helium.com/terms-and-conditions">https://www.helium.com/terms-and-conditions</a>) as they may be modified from time to time, and you agree to comply with such terms.</p>`,
  },
  {
    term: "License",
    condition: `<p>CoastFi grants you a no fee, limited, personal, worldwide, non-exclusive, revocable,terminable, nontransferable, nonassignable, internal uselicense (without the right to sublicense)to install and operate the Product as set forth in this Agreement. You do not acquire any ownership or other rights in the Product or in anything generated by or resulting from the operation of the Product, except for the limited license granted by this Agreement.</p>`,
  },
  {
    term: "Your Obligations",
    condition: `<p>Immediately after receipt by you of the Product you agree to install and operate it pursuant to instructions provided to you by CoastFi, and to keep the Product installed and operating at all times during the term of this Agreement. You agree not to alter, repair or modify the Product, or share, transfer or grant any interest in it to any third party.</p>`,
  },
  {
    term: "Payment",
    condition: `<p>CoastFi shall remit to you a fixed payment (as determined by CoastFi in its discretion) in exchange for the successful installation of the Product and the continuous maintenance of internet connectivity of the installed Product as set forth herein. Payment for installing a Product shall be remitted to you within 14 days after the successful installation of the Product. CoastFi shall also remit to you a fixed payment (as determined by CoastFi) for each 12 month period after the successful installation of the Product, provided that (i) internet connectivity of the Product is continuously maintained during each such period and (ii) you contact CoastFi via email (<a href="mailto:help@coastfi.com">help@coastfi.com</a>) within 30 days after the completion of each such 12 month period.<br/><br /> Payments may be delayed due to holidays, office closings, processing and other delays. Payments may be pro-rated for partial months. Payment will be remitted via Paypal, Venmo, Zelle, or check, to the Account specified by you.<br/><br/> CoastFi reserves the right to revoke or change the amount of the rate of payment without notice for any reason, in its sole and absolute discretion, including, but not limited to, issues relating to Product uptime, CoastFi network outages, and Product performance.</p>`,
  },
  {
    term: "Term of agreement",
    condition: `<p>The term of this Agreement shall commence on the date the Product is delivered to you and shall continue until terminated by CoastFi on notice to you. Upon termination you shall immediately return the Product to CoastFi pursuant to CoastFi’s shipping instructions. You may discontinue use of the Product at any time provided that (i) you notify CoastFi of such discontinuation via email (<a href="mailto:help@coastfi.com">help@coastfi.com</a>) and (ii) return the Product to CoastFi pursuant to CoastFi’s shipping instructions. If you do not return the Product in accordance with CoastFi’s shipping instructions CoastFi reserves the right to invoice you for the cost of the Product and you agree to pay such invoice within 15 days of the date thereof.</p>`,
  },
  {
    term: "Warranty Disclaimers and Limitation of Liability",
    condition: `<p>THE PRODUCT IS PROVIDED "AS IS" AND WITHOUT ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING ACCURACY, OPERABILITY, USE, TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND COASTFI HEREBY DISCLAIMS, ON BEHALF OF ITSELF AND ITS AFFILIATES, ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND REGARDING THE PRODUCT, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT, AND ANY WARRANTY THAT ITS USE WILL PRODUCE ANY RESULTS, FINANCIAL OR OTHERWISE. NEITHER COASTFI NOR ITS AFFILIATES SHALL BE LIABLE TO CUSTOMER OR ANY THIRD PARTY (A) FOR SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR LOST PROFITS (HOWEVER ARISING, INCLUDING NEGLIGENCE) ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF COASTFI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND (B) FOR DAMAGES, OF ANY NATURE WHATSOEVER, ARISING OUT OR IN CONNECTION WITH THIS AGREEMENT OR THE PRODUCT, INCLUDING BUT NOT LIMITED TO, DAMAGES FROM BREACH OF PRIVACY OR CONFIDENTIALITY,  OR RESULTING FROM DAMAGE TO YOUR NETWORK OR TO ANY DEVICES CONNECTED THERETO, OR FOR LOSS OF DATA. UNDER NO CIRCUMSTANCES SHALL COASTFI’S LIABILITY TO YOU EXCEED $500.00 IN THE AGGREGATE.</p>`,
  },
  {
    term: "Confidentiality",
    condition: `<p>Each party agrees to maintain the confidentiality of any confidential information (“Confidential Information”) of the other party that it has obtains or has access to. With regard to the other party’s Confidential Information each party shall (a) use and copy the Confidential Information only for purposes of performing its obligations under this Agreement, (b) hold the Confidential Information in strict confidence, and (c) protect and safeguard the confidentiality of the Confidential Information with at least the same degree of care as the recipient protects and safeguards its own confidential information (but not using less than a reasonable degree of care). The recipient may disclose that portion of the Confidential Information which, in the judgment of its counsel, the recipient is required to disclose by law, by regulation, or pursuant to the order or requirement of a court, administrative agency or other governmental body with proper jurisdiction; provided that the recipient notifies the disclosing party a reasonable time prior to making such disclosure and cooperates with the disclosing party's efforts to seek a protective order or otherwise prevent or restrict such disclosure. "Confidential Information" means all information disclosed by one party to the other that is designated, at the time of disclosure, as confidential or that is of a nature that would reasonably be considered confidential, and does not include information that the recipient can show (i) is known publicly through no breach of this Agreement, (ii) was in its possession free of any obligation of confidence at the time of disclosure, (iii) was received from a third party free to disclose such information without restriction, or (iv) was independently developed without using or referring to the information of the disclosing party or breaching this Agreement. </p>`,
  },
  {
    term: "Assignment",
    condition: `<p>This Agreement may not be assigned by you without the prior written consent of CoastFi. Any attempted assignment in violation of this provision is null and void.</p>`,
  },
  {
    term: "Miscellaneous",
    condition: `<p>This Agreement is governed by the laws of the State of New York, without regard to conflict of law rules. The UN Convention on Contracts for the International Sale of Goods will not apply to this Agreement. The parties shall bring any disputes arising out of or related to this Agreement exclusively in a court located in the State of New York and each party hereby submits to the personal jurisdiction of such courts. Each party is an independent contractor and neither party's personnel are employees or agents of the other party for any purpose whatsoever. Nothing hereunder will constitute, create, give effect to or otherwise recognize a joint venture, partnership or business entity of any kind, nor will anything hereunder constitute either party as the agent or representative of the other. Headings are for convenience. No presumption is to operate in either party's favor as a result of who drafted this Agreement. For purposes of this Agreement, the words "include," "includes" and "including" are deemed to be followed by the words "without limitation," and the word "or" is not exclusive. This Agreement supersedes all prior discussions and writings regarding (and constitutes the entire agreement between the parties with respect to) the subject matter of this Agreement. CoastFi reserves the right to amend this Agreement on notice to you. If any provision of this Agreement is for any reason held to be invalid, illegal, or unenforceable under applicable law in any respect, then such invalidity, illegality, or unenforceability will not affect the other provisions of this Agreement, this Agreement will be construed as if such invalid, illegal, or unenforceable provision were excluded from this Agreement, and the court in its discretion may substitute for the excluded provision an enforceable provision which in economic substance reasonably approximates the excluded provision.</p>`,
  },
];
